import { ApolloProvider } from "@apollo/client";
import { ChakraProvider } from "@chakra-ui/react";
import { useApollo } from "lib/apollo/client";
import { theme } from "lib/theme";
import { NextIntlClientProvider } from 'next-intl';
import Head from "next/head";

export default function App(props: any) {
  const { Component, pageProps } = props
  const apolloClient = useApollo(pageProps.initialApolloState)
  const getLayout = Component.getLayout ?? ((page: any) => page)
  return (
    <>
      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no" />
      </Head>

      <NextIntlClientProvider
        locale="pt-BR"
        timeZone="America/Sao_Paulo"
        messages={pageProps.messages}
      >
        <ChakraProvider theme={theme}>
          <ApolloProvider client={apolloClient}>
            {getLayout(<Component {...pageProps} />)}
          </ApolloProvider>
        </ChakraProvider>
      </NextIntlClientProvider>
    </>
  )
}
