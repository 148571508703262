export const MainPanel = {
  baseStyle: {
    float: "right",
    maxWidth: "100%",
    overflow: "auto",
    position: "relative",
    maxHeight: "100%",
    transition: "all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1)",
  },
  variants: {
    main: {
      float: "right",
      // additional styles for 'main' variant
    },
    rtl: {
      float: "left",
      // additional styles for 'rtl' variant
    },
  },
  // default props can be defined here
  defaultProps: {
    variant: "main",
  },
}
